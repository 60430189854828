<template>
  <HeadlessTransitionRoot
    :show="isOpen"
    as="template"
  >
    <HeadlessDialog
      class="relative z-50"
      @close="setIsOpen"
    >
      <HeadlessTransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-black bg-opacity-25"
          aria-hidden="true"
        />
      </HeadlessTransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <HeadlessTransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <HeadlessDialogPanel class="w-full relative max-w-2xl transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
              <button
                class="absolute top-1 right-2 text-gray text-2xl"
                @click="setIsOpen(false)"
              >
                <Icon name="mdi:close" />
              </button>

              <HeadlessDialogTitle class="text-black font-semibold text-2xl p-8 pb-6 border-b border-light">
                <slot name="header" />
              </HeadlessDialogTitle>

              <div class="p-8 pt-6 text-slate text-xl font-normal">
                <slot />
              </div>
            </HeadlessDialogPanel>
          </HeadlessTransitionChild>
        </div>
      </div>
    </HeadlessDialog>
  </HeadlessTransitionRoot>
</template>

<script setup lang="ts">
const isOpen = ref(false);

const setIsOpen = (value: boolean) => {
  isOpen.value = value;
};

defineExpose({
  setIsOpen
});
</script>
